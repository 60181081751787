import React, { useEffect, useState } from "react";
import axios from "axios";

const apiUrl = process.env.REACT_APP_BFF_API_SERVER_URL || "";

interface VersionMetadata {
  version: string;
  buildDate?: string;
  branch?: string;
  backendVersion: string;
  backendDate: string;
  bffVersion: string;
  bffDate: string;
}

const Version: React.FC = () => {
  const [metadata, setMetadata] = useState<VersionMetadata>({
    version: process.env.REACT_APP_VERSION || "unknown",
    buildDate: process.env.REACT_APP_BUILD_DATE || "unknown",
    branch: process.env.REACT_APP_GIT_BRANCH || "dev",
    backendVersion: "unkown",
    backendDate: "unkown",
    bffVersion: "unkown",
    bffDate: "unkown",
  });
  useEffect(() => {
    const fetchVersionDetials = async () => {
      try {
        const response = await axios.get(apiUrl);
        const data = response.data;
        const value = {
          version: process.env.REACT_APP_VERSION || "unknown",
          buildDate: process.env.REACT_APP_BUILD_DATE || "unknown",
          branch: process.env.REACT_APP_GIT_BRANCH || "dev",
          backendVersion: data["Backend Version"] || "unkown",
          backendDate: data["Backend Build Date"] || "unkown",
          bffVersion: data["BFF Version"] || "unkown",
          bffDate: data["BFF BUILD DATE"] || "unkown",
        };
        setMetadata(value);
      } catch (error) {
        console.error("Error Fetching Version number");
      }
    };
    fetchVersionDetials();
  }, []);
  return (
    <div className="flex flex-col items-center justify-center min-h-[80vh] w-full text-lg">
      <div>
        App Version: {metadata?.branch}/{metadata?.version}
      </div>
      <div>Build Date: {metadata?.buildDate}</div>
      <div>Backend Version: {metadata?.backendVersion}</div>
      <div>Backend Build Date: {metadata?.backendDate}</div>
      <div>BFF Version: {metadata?.bffVersion}</div>
      <div>BFF Build Date: {metadata?.bffDate}</div>
    </div>
  );
};

export default Version;
