// globals.ts

type GlobalState = {
    globalReturnUrl: string | null;
    globalFaceImage: string | null;
    globalPassportImage: string | null;
    globalXvfsSession: string | null;
};

let globalState: GlobalState = {
    globalReturnUrl: null,
    globalFaceImage: null,
    globalPassportImage: null,
    globalXvfsSession: null,
};

// Function to update the return URL
export const setGlobalReturnUrl = (url: string | null) => {
    globalState.globalReturnUrl = url;
};

// Function to update globalFaceImage
export const setGlobalFaceImage = (image: string | null) => {
    globalState.globalFaceImage = image;
};

// Function to update globalPassportImage
export const setGlobalPassportImage = (image: string | null) => {
    globalState.globalPassportImage = image;
};

// Function to update globalPassportImage
export const setGlobalXvfsSession = (id: string | null) => {
    globalState.globalXvfsSession = id;
};

// Function to get the current global state
export const getGlobalState = (): GlobalState => ({
    ...globalState,
});
