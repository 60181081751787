import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { Button } from "@mui/material";
import step1a from "../assets/step1a.png";
import step1b from "../assets/step1b.png";
import step1c from "../assets/step1c.png";
import passportbooklet from "../assets/step1c.png";
import AlertTitle from "@mui/material/AlertTitle";
import { passportDetection } from "../api/apiPassport";
import { useNavigate } from "react-router-dom";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import { CustomAlert } from "../styles/CustomElements";
import { useTranslation } from "react-i18next";
import PassportHelper from "./PassportHelper";

interface PassportVerificationElementInterface {
  handleProceed: () => void;
  handleFailure: () => void;
}

// const instructionSteps = {
//   step1: "Use laptop/tablet/mobile with high-res camera",
//   step2: "Keep your passport in hand",
//   step3: "Take out passport from any cover it may be in",
//   step4: "Show your passport as depicted",
// };

const PassportVerification: React.FC<PassportVerificationElementInterface> = ({
  handleProceed,
  handleFailure,
}) => {
  const webcamRef = useRef<Webcam>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isProcessStarted, setIsProcessStarted] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorHeader, setErrorHeader] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [image, setImage] = useState(step1a);
  const cancelFlag = useRef(false);
  const delayTime = parseInt(process.env.REACT_APP_DELAY_TIME_CAMERA || "3000");
  const maxCalls = parseInt(process.env.REACT_APP_NUMBER_OF_CALLS || "3");
  const count = useRef(0);
  const [instructionDone, setInstructionDone] = useState(false);
  const [remaningChances, setRemaningChances] = useState(3);

  const instructionSteps = {
    step1: t("passportInstructionStep1"),
    step2: t("passportInstructionStep2"),
    step3: t("passportInstructionStep3"),
    step4: t("passportInstructionStep4"),
  };

  const step1Images = [step1a, step1b, step1c];

  const getRandomImage = async () => {
    while (!cancelFlag.current) {
      const randomIndex = Math.floor(Math.random() * step1Images.length);
      setImage(step1Images[randomIndex]);

      await delay(5000);
    }

    return;
  };

  const detectPassportAndCheckPhoto = async (): Promise<void> => {
    try {
      while (!cancelFlag.current && count.current < maxCalls) {
        const imageSrc = webcamRef.current?.getScreenshot();
        // console.log("count", count.current);
        if (!imageSrc) {
          // console.log("Failed to capture image from webcam.");
          await delay(delayTime);
          continue;
        }
        const imageData = {
          image: imageSrc.split(",")[1],
        };
        const data = await passportDetection(imageData);
        if (data === -1) {
          navigate("/sessionexpired");
          return;
        }
        if (cancelFlag.current) return;
        if (data?.terminate) {
          setIsProcessStarted(false);
          cancelFlag.current = true;
          setSuccessMessage("");
          setErrorHeader(t("passportErrorHeaderMobile"));
          setErrorMessage(t("passportErrorMessageMobile"));
          setRemaningChances(remaningChances - 1);
          if (remaningChances < 2) {
            handleFailure();
            return;
          }
          return;
        }
        if (data?.passport) {
          setSuccessMessage(t("passportSuccessHeader"));
          setErrorMessage("");
          setErrorHeader("");
          handleProceed();
          return;
        }
        count.current += 1;
        await delay(delayTime);
      }
      setIsProcessStarted(false);
      cancelFlag.current = true;
      setSuccessMessage("");
      setErrorHeader(t("passportErrorHeaderNoPassport"));
      setErrorMessage(t("passportErrorMessageNoPassport"));
      setRemaningChances(remaningChances - 1);
      if (remaningChances < 2) {
        handleFailure();
        return;
      }
    } catch (error) {
      console.error("Error during API call:", error);
      setIsProcessStarted(false);
      cancelFlag.current = true;
      setSuccessMessage("");
      setErrorHeader(t("passportErrorHeaderUnexpected"));
      setErrorMessage(t("passportErrorMessageUnexpected"));
    }
  };

  useEffect(() => {
    if (isProcessStarted) {
      detectPassportAndCheckPhoto();
    }
    getRandomImage();
  }, [isProcessStarted]);

  const delay = (ms: number): Promise<void> =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const handleStartProcess = async () => {
    setIsProcessStarted(true);
    setSuccessMessage("");
    setErrorMessage("");
    setErrorHeader("");
    cancelFlag.current = false;
    count.current = 0;
  };

  const handleCancelProcess = () => {
    cancelFlag.current = true;
    count.current = 0;
    setIsProcessStarted(false);
    setErrorHeader(t("passportErrorHeaderCanceled"));
    setErrorMessage(t("passportErrorMessageCanceled"));
    setSuccessMessage("");
    setRemaningChances(remaningChances - 1);
    if (remaningChances < 2) {
      handleFailure();
      return;
    }
  };
  const handleInstruction = () => {
    setIsProcessStarted(true);
    setInstructionDone(true);
  };
  if (!instructionDone) {
    return <PassportHelper handleInstruction={handleInstruction} />;
  }
  return (
    <div className="flex flex-col items-center  w-full rounded-md border border-gray  mb-3">
      <div className="w-full items-center text-center text-md md:text-lg p-2 font-semibold">
        {t("passportHelperStep1Header")}
      </div>
      <div className="flex flex-col items-center w-full rounded-md border border-gray  mb-3">
        {successMessage ? (
          <CustomAlert severity="success" headerMessage={successMessage} />
        ) : (
          <>
            {errorMessage && (
              <CustomAlert severity="error" headerMessage={errorHeader}>
                <p>
                  {errorMessage} {t("passportErrorFooter")}
                </p>
              </CustomAlert>
            )}
            {/* {errorMessage ? (
              <CustomAlert severity="error" headerMessage={errorHeader}>
                <p>
                  {errorMessage}.{t("passportErrorFooter")}
                </p>
              </CustomAlert>
            ) : (
              <CustomAlert
                severity="info"
                headerMessage={t("passportInfoHeader")}
              />
            )} */}
          </>
        )}
      </div>

      <div className="flex flex-col items-center md:w-6/12 w-full m-3">
        {!isProcessStarted ? (
          <div className="flex flex-col w-full p-2 items-center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleStartProcess}
              autoFocus
              endIcon={<VideocamOutlinedIcon />}
              fullWidth
            >
              {t("retry")}
            </Button>
            {/* <img
              src={image}
              alt={t("altGuide")}
              style={{ width: "auto", height: 280, margin: 20, opacity: 0.3 }}
            /> */}
            <div className="bg-white shadow-lg rounded-lg flex flex-col items-center p-4 w-full mt-2 border border-gray-100 hover:shadow-xl transition-shadow duration-300">
              <div className="w-full h-44 flex items-center justify-center border border-dashed border-gray-300 rounded-lg mb-4 bg-gray-50 hover:bg-gray-100 transition-colors duration-300">
                <img
                  src={passportbooklet}
                  alt={t("passport")}
                  className="w-full h-full object-contain p-2"
                />
              </div>
              <ul className="w-full">
                <li className="flex items-start space-x-3 p-2 rounded-md">
                  <span className="text-sm text-gray-400 mt-1">●</span>
                  <span className=" md:text-lg text-base ">
                    {t("passportHelperStep1Tip1")}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div className="w-full flex flex-col items-center  p-2 md:p-1">
            <div>
              <Webcam
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                className="w-full h-auto rounded-md shadow-md"
                forceScreenshotSourceSize
                videoConstraints={{
                  facingMode: "environment",
                  height: 1080,
                  width: 1920,
                }}
                height="360"
                width="640"
              />
            </div>

            <Button
              variant="outlined"
              color="primary"
              onClick={handleCancelProcess}
              sx={{
                // borderRadius: "20px",
                marginTop: 3,
              }}
              fullWidth
            >
              {t("cancelProcess")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PassportVerification;
