import React, { useState, useEffect } from "react";
import {
  AwsCredentialProvider,
  FaceLivenessDetectorCore,
} from "@aws-amplify/ui-react-liveness";
import {
  createLivenessSession,
  getLivenessSessionResult,
} from "../api/apiFaceLiveness";
import "@aws-amplify/ui-react/styles.css";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { Theme, ThemeProvider } from "@aws-amplify/ui-react";

import { setGlobalFaceImage } from "../Utility/globalVariables";
import { CustomAlert } from "../styles/CustomElements";
import { useTranslation } from "react-i18next";
import LinearDeterminate from "../styles/ProgressBar";
import IdentityVerification from "./IdentityHelper";

interface FaceLivenessElementInterface {
  handleNext: (num?: number, delaytime?: number) => void;
}
interface ResultInterface {
  confidence: number;
  status: string;
  referenceImageBase64: string | null;
  message: string;
  code: string;
}

const FaceLiveness: React.FC<FaceLivenessElementInterface> = ({
  handleNext,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [isLivenessTest, setIsLivenessTest] = useState<boolean>(false);
  const [createLivenessApiData, setCreateLivenessApiData] = useState<{
    sessionId: string;
  } | null>(null);

  const [cognitoConfig, setCognitoConfig] = useState({
    accessKeyId: "",
    secretAccessKey: "",
    sessionToken: "",
  });
  const [result, setResult] = useState<ResultInterface | null>(null);
  const [isProcessCompleted, setIsProcessCompleted] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const navigate = useNavigate();
  const [instructionDone, setInstructionDone] = useState(false);
  const [remaningChances, setRemaningChances] = useState(3);
  const [livenessRegion, setLivenessRegion] = useState("us-east-1");

  // Custom messages for Facedectector core (amplify element)
  const dictionary = {
    errorLabelText: t("errorLabelText"),
    connectionTimeoutHeaderText: t("connectionTimeoutHeaderText"),
    connectionTimeoutMessageText: t("connectionTimeoutMessageText"),
    timeoutHeaderText: t("timeoutHeaderText"),
    timeoutMessageText: t("timeoutMessageText"),
    faceDistanceHeaderText: t("faceDistanceHeaderText"),
    faceDistanceMessageText: t("faceDistanceMessageText"),
    multipleFacesHeaderText: t("multipleFacesHeaderText"),
    multipleFacesMessageText: t("multipleFacesMessageText"),
    clientHeaderText: t("clientHeaderText"),
    clientMessageText: t("clientMessageText"),
    serverHeaderText: t("serverHeaderText"),
    serverMessageText: t("serverMessageText"),
    landscapeHeaderText: t("landscapeHeaderText"),
    landscapeMessageText: t("landscapeMessageText"),
    portraitMessageText: t("portraitMessageText"),
    tryAgainText: t("tryAgainText"),
    cameraMinSpecificationsHeadingText: t("cameraMinSpecificationsHeadingText"),
    cameraMinSpecificationsMessageText: t("cameraMinSpecificationsMessageText"),
    cameraNotFoundHeadingText: t("cameraNotFoundHeadingText"),
    cameraNotFoundMessageText: t("cameraNotFoundMessageText"),
    a11yVideoLabelText: t("a11yVideoLabelText"),
    cancelLivenessCheckText: t("cancelLivenessCheckText"),
    goodFitCaptionText: t("goodFitCaptionText"),
    goodFitAltText: t("goodFitAltText"),
    hintCenterFaceText: t("hintCenterFaceText"),
    hintCenterFaceInstructionText: t("hintCenterFaceInstructionText"),
    hintFaceOffCenterText: t("hintFaceOffCenterText"),
    hintMoveFaceFrontOfCameraText: t("hintMoveFaceFrontOfCameraText"),
    hintTooManyFacesText: t("hintTooManyFacesText"),
    hintFaceDetectedText: t("hintFaceDetectedText"),
    hintCanNotIdentifyText: t("hintCanNotIdentifyText"),
    hintTooCloseText: t("hintTooCloseText"),
    hintTooFarText: t("hintTooFarText"),
    hintConnectingText: t("hintConnectingText"),
    hintVerifyingText: t("hintVerifyingText"),
    hintCheckCompleteText: t("hintCheckCompleteText"),
    hintIlluminationTooBrightText: t("hintIlluminationTooBrightText"),
    hintIlluminationTooDarkText: t("hintIlluminationTooDarkText"),
    hintIlluminationNormalText: t("hintIlluminationNormalText"),
    hintHoldFaceForFreshnessText: t("hintHoldFaceForFreshnessText"),
    hintMatchIndicatorText: t("hintMatchIndicatorText"),
    retryCameraPermissionsText: t("retryCameraPermissionsText"),
    recordingIndicatorText: t("recordingIndicatorText"),
    startScreenBeginCheckText: t("startScreenBeginCheckText"),
    tooFarCaptionText: t("tooFarCaptionText"),
    tooFarAltText: t("tooFarAltText"),
    waitingCameraPermissionText: t("waitingCameraPermissionText"),
  };
  const theme: Theme = {
    name: "Face Liveness",
    tokens: {
      fonts: {
        default: {
          variable: { value: "Inter-UI-500, Arial, sans-serif" },
          static: { value: "Inter-UI-500, Arial, sans-serif" },
        },
      },
      colors: {
        background: {
          primary: {
            value: "#eeeeee",
          },
          secondary: {
            value: "#dddddd",
          },
        },
        font: {
          primary: {
            value: "#00000",
          },
        },
      },
    },
  };

  // credential provider
  const credentialProvider: AwsCredentialProvider = async () => {
    return cognitoConfig;
  };
  // Fetech Session ID for Liveness check
  const fetchCreateLiveness: () => Promise<string> = async () => {
    try {
      const data = await createLivenessSession();
      if (data === -1) {
        navigate("/sessionexpired");
        return;
      }
      const decoded = atob(data["Data"]?.Credentials);
      const temp = JSON.parse(decoded);
      const cognitoTemp = {
        accessKeyId: temp.AccessKeyId,
        secretAccessKey: temp.SecretAccessKey,
        sessionToken: temp.SessionToken,
      };
      setCognitoConfig(cognitoTemp);
      setLivenessRegion(temp.LivenessRegion);
      setCreateLivenessApiData({ sessionId: data["Data"]?.SessionId ?? "" });
      return data["Data"]?.SessionId;
    } catch (error) {
      console.error("Error Fetching Session Id for Liveness", error);
      setLoading(false);
      const resultTemp = {
        confidence: 0,
        status: "",
        referenceImageBase64: null,
        message: "",
        code: "LivenessError_Unexpected",
      };
      setResult(resultTemp);
    }
  };

  const handleFaceLivessStart = async () => {
    setLoading(true);
    const data = await fetchCreateLiveness();
    setLoading(false);
    if (data) {
      setResult(null);
      setIsLivenessTest(true);
    }
  };

  const handleCancel = async () => {
    setCreateLivenessApiData(null);
    setIsLivenessTest(false);
    setResult(null);
    setIsProcessCompleted(false);
    setInstructionDone(false);
  };

  const handleAnalysisComplete: () => Promise<void> = async () => {
    try {
      if (!createLivenessApiData?.sessionId) return;
      const tempData = await getLivenessSessionResult(
        createLivenessApiData?.sessionId
      );
      // console.log("TempData", tempData);
      if (tempData === -1) {
        navigate("/sessionexpired");
        return;
      }
      const data = tempData?.Data;

      const resultTemp = {
        confidence: data?.confidence,
        status: data?.status,
        referenceImageBase64: data?.reference_image
          ? `data:image/jpeg;base64,${data.reference_image}`
          : null,
        message: data?.face_validation_message || "",
        code: data?.error_code || "",
      };
      setResult(resultTemp);
      // sessionStorage.setItem(
      //   "ReferenceImage",
      //   resultTemp?.referenceImageBase64 ?? ""
      // );
      setGlobalFaceImage(resultTemp?.referenceImageBase64);
      setIsLivenessTest(false);
      setIsProcessCompleted(true);
      if (data?.status === "passed") {
        setIsSuccess(true);
        if (tempData?.route === "route-2") {
          handleNext(2);
        } else {
          handleNext();
        }
      } else {
        setRemaningChances(remaningChances - 1);
        if (remaningChances < 2) {
          handleNext(3, 0);
          return;
        }
      }
    } catch (error) {
      console.error("Error fetching Results:", error);
      setIsLivenessTest(false);
      const resultTemp = {
        confidence: 0,
        status: "",
        referenceImageBase64: null,
        message: "",
        code: "LivenessError_Unexpected",
      };
      setResult(resultTemp);
    }
  };

  const handleInstruction = () => {
    setInstructionDone(true);
    handleFaceLivessStart();
  };

  if (!instructionDone) {
    return <IdentityVerification handleInstruction={handleInstruction} />;
  }
  return (
    <div className="flex flex-col items-center w-full border border-gray min-h-[300px]">
      {result && (
        <div className="flex flex-col items-center w-full rounded-md border border-gray mb-4">
          {isSuccess ? (
            <CustomAlert
              severity="success"
              headerMessage={t("livenessSuccessHeader")}
            >
              <p className="text-md">{t("livenessSuccessMessage")}</p>
              <LinearDeterminate />
            </CustomAlert>
          ) : (
            <CustomAlert
              severity="error"
              headerMessage={t("livenessErrorHeader")}
            >
              {t(result?.code)} <br />
              <Button
                variant="contained"
                color="primary"
                onClick={handleCancel}
                sx={{
                  padding: "5px 25px",
                  marginTop: 3,
                }}
                autoFocus
              >
                {t("retry")}
              </Button>
            </CustomAlert>
          )}{" "}
        </div>
      )}

      {!isProcessCompleted ? (
        <div className="flex flex-col items-center lg:w-8/12 rounded-md mb-3">
          {isLivenessTest && createLivenessApiData && (
            <ThemeProvider theme={theme}>
              <div
                className="fixed inset-0 z-50 flex items-center justify-center min-h-screen min-w-full"
                style={{ backgroundColor: " rgba(236, 236, 236, 0.9)" }}
              >
                <FaceLivenessDetectorCore
                  sessionId={createLivenessApiData?.sessionId}
                  region={livenessRegion}
                  onAnalysisComplete={handleAnalysisComplete}
                  onError={(error) => {
                    console.error(error);
                    setIsLivenessTest(false);
                    const resultTemp = {
                      confidence: 0,
                      status: "",
                      referenceImageBase64: null,
                      message: "",
                      code: "LivenessError_Unexpected",
                    };
                    setResult(resultTemp);
                    setRemaningChances(remaningChances - 1);
                    if (remaningChances < 2) {
                      handleNext(3, 0);
                      return;
                    }
                  }}
                  onUserCancel={handleCancel}
                  disableStartScreen
                  config={{
                    binaryPath: "/wasm-files/",
                    faceModelUrl: "/blazeface/model.json",
                    credentialProvider,
                  }}
                  displayText={dictionary}
                />
              </div>
            </ThemeProvider>
          )}
          <div className="flex w-full items-center justify-center p-4">
            {loading && <CircularProgress size={60} />}
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center lg:w-8/12 rounded-md p-2">
          {result?.referenceImageBase64 && (
            <img
              src={result?.referenceImageBase64}
              alt={t("altReference")}
              className="w-full h-auto lg:w-96 rounded-md"
            />
          )}
        </div>
      )}
    </div>
  );
};

export default FaceLiveness;
