import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { initiatePortalSession } from "../api/apiHelper";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const CallbackPage: React.FC = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const languagecode = urlParams.get("language");
  const { t, i18n } = useTranslation();
  useEffect(() => {
    // Extract parameters from the URL
    const fetchParam = async () => {
      try {
        sessionStorage.setItem("navipreferedLanguage", languagecode || "en");
        i18n.changeLanguage(languagecode || "en");
        if (token) {
          const tokenId = token;
          const queryParams = {
            language_code: languagecode || "en",
          };

          const result = await initiatePortalSession(tokenId, queryParams);
          if (result) {
            navigate("/home");
          }
        } else {
          navigate("/error401");
        }
      } catch (error) {
        navigate("/error401");
      }
    };
    fetchParam();
  }, [navigate]);

  return (
    <Grid
      item
      xs={12}
      sx={{
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
      }}
      p={3}
    >
      <CircularProgress />
      <Typography variant="h6" sx={{ mt: 2 }}>
        {t("callbackPageHeader")}
      </Typography>
    </Grid>
  );
};

export default CallbackPage;
