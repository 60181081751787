import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Error404: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      textAlign="center"
      p={3}
    >
      <Grid item xs={12}>
        <Typography variant="h4" sx={{ fontWeight: 500, color: "#170F49" }}>
          {t("error404Header")}
        </Typography>
        {/* <Typography
          variant="subtitle1"
          sx={{ marginTop: 1, color: "#626567 " }}
        >
          {t("error404Message")}
        </Typography> */}
      </Grid>
    </Grid>
  );
};

export default Error404;
