import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const SessionNotConnected: React.FC = () => {
  const { t } = useTranslation();
  // useEffect(() => {
  //   // const timer = setTimeout(() => {
  //   //   handleRedirect(); // Replace with your eVisa portal URL
  //   // }, 5000);

  //   // return () => clearTimeout(timer);
  //   handleRedirect(5000);
  // }, []);

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      textAlign="center"
      p={3}
    >
      <Grid item xs={12} md={6}>
        <Typography variant="h4" sx={{ fontWeight: 500, color: "#170F49" }}>
          {t("errorNotConnectedHeader")}
        </Typography>

        {/* <Typography variant="subtitle1" sx={{ marginTop: 5, color: "#626567" }}>
          {t("errorNotConnectedMessage")}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleRedirect()}
          sx={{ borderRadius: "20px", padding: "10px 50px", marginTop: 3 }}
        >
          {t("goback")}
        </Button> */}
      </Grid>
    </Grid>
  );
};

export default SessionNotConnected;
