import Alert, { AlertProps, AlertColor } from "@mui/material/Alert";
import styles from "./common.module.scss";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

interface CustomAlertProps extends AlertProps {
  children?: React.ReactNode;
  iconSize?: number;
  headerMessage?: string;
}

export const CustomAlert: React.FC<CustomAlertProps> = ({
  children,
  severity = "success",
  iconSize = 48,
  headerMessage,
  ...props
}) => {
  const className: Record<AlertColor, string> = {
    success: styles.successAlert,
    error: styles.errorAlert,
    info: styles.infoAlert,
    warning: styles.warningAlert,
  };

  const ariaLive: Record<AlertColor, "polite" | "assertive"> = {
    success: "polite",
    error: "assertive",
    info: "polite",
    warning: "assertive",
  };

  const handleIcon = () => {
    if (severity === "success") {
      return (
        <TaskAltRoundedIcon
          sx={{
            fontSize: iconSize,
            color: "green",
            // marginBottom: 2,
            borderRadius: "8px",
          }}
        />
      );
    }
    if (severity === "error") {
      return (
        <ErrorOutlineRoundedIcon
          sx={{
            fontSize: iconSize,
            color: "#8B0000",
            // marginBottom: 2,
            borderRadius: "8px",
          }}
        />
      );
    }
    if (severity === "warning") {
      return (
        <WarningAmberRoundedIcon
          sx={{
            fontSize: iconSize,
            color: "#ff8503",
            // marginBottom: 2,
            borderRadius: "8px",
          }}
        />
      );
    }
    return null;
  };
  return (
    <Alert
      severity={severity}
      className={className[severity]}
      aria-live={ariaLive[severity]}
      icon={false}
      {...props}
    >
      <div className="flex flex-row w-full text-sm md:text-base space-x-4">
        <div>{handleIcon()}</div>
        <div>
          <h2 className="text-base md:text-lg font-bold">{headerMessage}</h2>
          {children}
        </div>
      </div>
    </Alert>
  );
};
