import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import { handleRedirect } from "../../api/apiHelper";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useTranslation } from "react-i18next";

const LimitExceededLiveness: React.FC = () => {
  const { t } = useTranslation();
  useEffect(() => {
    handleRedirect(5000, "LimitExceededLiveness");
  }, []);

  return (
    <div>
      <div className="text-center mt-16 p-4">
        <div>
          <HighlightOffIcon className="text-red-700" sx={{ fontSize: 48 }} />
        </div>
        <div className="text-sm text-BlackPearl mt-1">
          {t("errorLimitExceedHeader")}
        </div>
        <div className="text-sm text-BlackPearl mt-1">
          {t("errorLimitExceedMessage")}
        </div>
        <Button
          variant="outlined"
          className="!mt-2"
          onClick={() => handleRedirect()}
          autoFocus
        >
          {t("continue")}
        </Button>
      </div>
    </div>
  );
};
export default LimitExceededLiveness;
