// src/App.tsx
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import HomePage from "./components/Home";
import PageLayout from "./components/PageLayout";
import Error404 from "./components/Errors/Error404";
import CallbackPage from "./components/CallbackPage";
import SessionExpired from "./components/Errors/SessionExpired";
import SessionNotConnected from "./components/Errors/SessionNotConnected";
import Version from "./Utility/Version";

export const routeConfig = createBrowserRouter([
  {
    path: "/",
    element: <PageLayout />,
    children: [
      {
        index: true,
        element: <CallbackPage />,
      },
      {
        path: "/404",
        element: <Error404 />,
      },
      {
        path: "/home",
        element: <HomePage />,
      },
      {
        path: "/callback",
        element: <CallbackPage />,
      },
      {
        path: "/sessionexpired",
        element: <SessionExpired />,
      },
      {
        path: "/error401",
        element: <SessionNotConnected />,
      },
      {
        path: "*",
        element: <Error404 />,
      },
      {
        path: "version",
        element: <Version />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={routeConfig} />;
}

export default App;
