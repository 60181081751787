import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getGlobalState, setGlobalReturnUrl, setGlobalXvfsSession } from '../Utility/globalVariables';
// const apiUrl = process.env.REACT_APP_API_SERVER_URL;
const apiUrl = process.env.REACT_APP_BFF_API_SERVER_URL;
interface InitiatePortalSessionResponse {
    message: string;
    'x-VFS-session': string;
    browser_signature: string;
    return_url: string;
}
const handleCallback = async (xVfsSession: string, reason: string = '') => {
    try {
        const userInfo = getUserDimensions();
        const headers = {
            'accept': 'application/json',
            "Content-Type": "multipart/form-data",
            'x-vfs-session': xVfsSession,
            'viewport': `${userInfo.viewport.width}x${userInfo.viewport.height}`,
            'screen_resolution': `${userInfo.screenResolution.width}x${userInfo.screenResolution.height}`, // Use the same dimensions for screen resolution
            'narrative_language': userInfo.narrativeLanguage,
            'reason': reason,
        };
        const URL = apiUrl + 'initiate-callback';
        await axios.get(URL, {
            headers
        });

        return true

    } catch (error) {
        console.error("Error CallBack function");
        return true
    }
}
const delay = (ms: number): Promise<void> =>
    new Promise((resolve) => setTimeout(resolve, ms));


export const handleRedirect = async (delayTime = 0, reason = '') => {
    const xVfsSession = sessionStorage.getItem('x-VFS-session');
    if (xVfsSession) {
        // await handleCallback(xVfsSession);
        handleCallback(xVfsSession, reason);
        await delay(delayTime);
    }
    const returnUrl = getGlobalState()?.globalReturnUrl || sessionStorage.getItem("return_url") || '';
    if (returnUrl) {
        window.location.href = returnUrl;
    }
};

export const getUserDimensions = () => {
    return {
        viewport: {
            width: window.innerWidth,
            height: window.innerHeight
        },
        screenResolution: {
            width: window.screen.width,
            height: window.screen.height
        },
        narrativeLanguage: navigator.language || 'en'
    };
};

export const initiatePortalSession = async (
    tokenId: string,
    queryParams: {
        language_code?: string;
        return_url?: string;
        referer?: string;
    }
): Promise<InitiatePortalSessionResponse> => {
    try {
        const userInfo = getUserDimensions();
        const headers = {
            'accept': 'application/json',
            'token-id': tokenId,
            'viewport': `${userInfo.viewport.width}x${userInfo.viewport.height}`,
            'screen_resolution': `${userInfo.screenResolution.width}x${userInfo.screenResolution.height}`, // Use the same dimensions for screen resolution
            'narrative_language': userInfo.narrativeLanguage
        };

        // console.log('Sending request with headers:', headers);
        // console.log('Query parameters:', queryParams);
        const URL = apiUrl + 'initiate-portal-session';
        // console.log('test', URL);
        const response = await axios.post<InitiatePortalSessionResponse>(
            URL,
            null,
            {
                headers,
                params: queryParams
            }
        );
        sessionStorage.setItem('x-VFS-session', response.data['x-VFS-session']);
        setGlobalReturnUrl(response.data['return_url']);
        setGlobalXvfsSession(response.data['x-VFS-session']);
        sessionStorage.setItem('return_url', response.data['return_url'] || '');
        return response.data;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            if (error.response.status === 401) {
                const returnUrl = error.response.data?.detail?.return_url;
                // sessionStorage.setItem('return_url', returnUrl || '');
                setGlobalReturnUrl(returnUrl);
            }
            console.error('Error:', error.response.data);
        } else {
            console.error('Error:', error);
        }
        throw error;
    };
}

