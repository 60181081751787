import { CircularProgress, Typography, Button, Box } from "@mui/material";
import css from "./header.module.scss";
import { handleRedirect } from "../../api/apiHelper";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TranslateRoundedIcon from "@mui/icons-material/TranslateRounded";
import { useLocation } from "react-router-dom";
const languageOptions: Record<string, string> = {
  en: "English (en)",
  pl: "Polski (pl)",
  fr: "Français (fr)",
  hi: "हिन्दी (hi)",
  ru: "русский (ru)",
  pt: "Português (pt)",
};

// const usePreventGoingBack = () => {
//   useEffect(() => {
//     // Handle back button
//     const preventGoBack = (e: any) => {
//       e.preventDefault();
//       // Your custom back button logic
//       const shouldLeave = window.confirm("Are you sure you want to go back?");
//       if (shouldLeave) {
//         window.history.back();
//       }
//     };

//     // Handle page leave/refresh/close
//     const handlePageLeave = (e: any) => {
//       e.preventDefault();
//       // Browser will show its own dialog
//       return (e.returnValue = "Are you sure you want to leave?");
//     };

//     // Set up both listeners
//     window.history.pushState(null, "", window.location.pathname);
//     window.addEventListener("popstate", preventGoBack);
//     window.addEventListener("beforeunload", handlePageLeave);

//     // Clean up
//     return () => {
//       window.removeEventListener("popstate", preventGoBack);
//       window.removeEventListener("beforeunload", handlePageLeave);
//     };
//   }, []);
// };

const Header: React.FC = () => {
  // usePreventGoingBack();
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const [selectedLang, setSelectedLang] = useState<string>(i18n.language || "");
  const location = useLocation();

  useEffect(() => {
    const languageCodeSession = sessionStorage.getItem("navipreferedLanguage");
    setSelectedLang(languageCodeSession || "en");
    if (languageCodeSession && languageCodeSession !== i18n.language) {
      i18n.changeLanguage(languageCodeSession);
    }
  }, [i18n.language]);

  const handleCancel = async () => {
    try {
      setLoading(true);
      await handleRedirect(2000, "Cancel");
    } catch (error) {
      console.error("Error in CallBack:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="flex h-20 items-center px-5 lg:px-24 justify-between">
      <div>
        <img
          src="images/Header/vfs-global-logo.svg"
          className={`${css.logo}`}
          alt={t("altLogo")}
        />
      </div>
      <div className="flex flex-row space-x-5 items-center">
        <div>
          <Typography variant="body1" style={{ marginTop: 3 }} component="div">
            <TranslateRoundedIcon />
            <Box sx={{ display: { xs: "none", sm: "inline" } }}>
              : {languageOptions[selectedLang] || "English (en)"}
            </Box>
            <Box sx={{ display: { xs: "inline", sm: "none" } }}>
              : {selectedLang || "en"}
            </Box>
          </Typography>
        </div>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {location.pathname === "/home" && (
              <Button
                variant="outlined"
                className="!mt-2"
                onClick={handleCancel}
              >
                {t("cancel")}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default Header;
