import React from "react";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import passportbooklet from "../assets/step1c.png";
import passportdetails from "../assets/step2b.png";

const passportFrontImage = passportbooklet;
const passportPhotoPageImage = passportdetails;

interface PassportHelperElementInterface {
  handleInstruction: () => void;
}

const PassportHelper: React.FC<PassportHelperElementInterface> = ({
  handleInstruction,
}) => {
  const { t } = useTranslation(); // Translation hook

  return (
    <div className="flex flex-col items-center px-2 sm:px-4 w-full">
      <h2 className="text-xl md:text-3xl font-semibold text-gray-800 mb-6">
        {t("passportHelperHeader")}
      </h2>

      {/* Content Section */}
      <div className="flex flex-col md:flex-row justify-between w-full mt-2 md:space-x-6">
        {/* Card 1: Front of Passport */}
        <div className="bg-white shadow-lg rounded-lg flex flex-col items-center px-4 py-4 w-full md:w-2/5 border border-gray-100 hover:shadow-xl transition-shadow duration-300">
          <h3 className="text-lg font-medium text-center mb-4 mt-2 ">
            {t("passportHelperStep1Header")}
          </h3>
          <div className="w-full h-44 flex items-center justify-center border border-dashed border-gray-300 rounded-lg mb-4 bg-gray-50 hover:bg-gray-100 transition-colors duration-300">
            <img
              src={passportFrontImage}
              alt={t("imageBookletAlt1")}
              className="w-full h-full object-contain p-2"
            />
          </div>
          <ul className="w-full">
            <li className="flex items-start space-x-3 p-2 rounded-md">
              <span className="text-sm text-gray-400 mt-1">●</span>
              <span className=" md:text-lg text-base ">
                {t("passportHelperStep1Tip1")}
              </span>
            </li>
          </ul>
        </div>

        {/* Middle Arrow Section */}
        <div className="flex items-center justify-center h-100 ">
          <ArrowForwardRoundedIcon
            className="rotate-90 md:rotate-0"
            sx={{
              fontSize: 60,
              color: "#e86020",
              animation: "bounce 1s infinite",
              alignItems: "center",
              transition: "transform 1s ease",
            }}
          />
        </div>

        {/* Card 2: Passport Photo Page */}
        <div className="bg-white shadow-lg rounded-lg flex flex-col items-center px-4 py-4 w-full md:w-2/5 mt-2 border border-gray-100 hover:shadow-xl transition-shadow duration-300">
          <h3 className="text-lg font-medium text-center mb-4 ">
            {t("passportHelperStep2Header")}
          </h3>
          <div className="w-full h-44 flex items-center justify-center border border-dashed border-gray-300 rounded-lg mb-4 bg-gray-50 hover:bg-gray-100 transition-colors duration-300">
            <img
              src={passportPhotoPageImage}
              alt={t("imagePassportAlt2")}
              className="w-full h-full object-contain p-2"
            />
          </div>
          <ul className="space-y-2 w-full">
            <li className="flex items-start space-x-3 ">
              <span className="text-sm text-gray-400 mt-1">●</span>
              <span className=" md:text-lg text-base ">
                {t("passportHelperStep2Tip1")}
              </span>
            </li>
            <li className="flex items-start space-x-3  ">
              <span className="text-sm text-gray-400 mt-1">●</span>
              <span className=" md:text-lg text-base ">
                {t("passportHelperStep2Tip2")}
              </span>
            </li>
            <li className="flex items-start space-x-3  ">
              <span className="text-sm text-gray-400 mt-1">●</span>
              <span className=" md:text-lg text-base ">
                {t("passportHelperStep2Tip3")}
              </span>
            </li>
            <li className="flex items-start space-x-3  ">
              <span className="text-sm text-gray-400 mt-1">●</span>
              <span className=" md:text-lg text-base ">
                {t("passportHelperStep2Tip4")}
              </span>
            </li>
            <li className="flex items-start space-x-3  ">
              <span className="text-sm text-gray-400 mt-1">●</span>
              <span className=" md:text-lg text-base ">
                {t("passportHelperStep2Tip5")}
              </span>
            </li>
          </ul>
        </div>
      </div>

      {/* Continue Button Section */}
      <div className="w-full flex justify-center mt-4 mb-4">
        <Button
          variant="contained"
          color="primary"
          onClick={handleInstruction}
          autoFocus
          sx={{ width: "30%", mt: 2 }}
        >
          {t("continue")}
        </Button>
      </div>
    </div>
  );
};

export default PassportHelper;
