import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getGlobalState } from './globalVariables';

// Preventing unauthorized Access
export const Validate = () => {
    const navigate = useNavigate();
    const code = getGlobalState().globalXvfsSession;
    // const code = sessionStorage.getItem('x-VFS-session');
    // console.log(code);
    useEffect(() => {
        if (code)
            return;
        else
            navigate('/404');
        return;
    })
}