import React, { useState } from "react";

import Button from "@mui/material/Button";

import FaceLiveness from "./FaceLiveness";
import { Validate } from "../Utility/Validation";
import PassportCapture from "./PassportCapture";
import SuccessPage from "./SuccessPage";
import { useTranslation } from "react-i18next";
import LimitExceededPassport from "./Errors/LimitExceededPassport";
import LimitExceededLiveness from "./Errors/LimitExceededLiveness";

const HomePage: React.FC = () => {
  Validate();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const delayTime = parseInt(process.env.REACT_APP_STEP_DELAY_TIME || "5000");
  const delay = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const handleNext = async (num: number = 1, delaytime: number = delayTime) => {
    await delay(delaytime);
    setActiveStep((prevActiveStep) => prevActiveStep + num);
  };

  const handlePrevious = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <div>
      <div className="px-2 lg:px-12 mt-4 lg:mt-8">
        <div>
          {activeStep === 0 && <FaceLiveness handleNext={handleNext} />}
          {activeStep === 1 && <PassportCapture handleNext={handleNext} />}
          {activeStep === 2 && <SuccessPage />}
          {activeStep === 3 && <LimitExceededLiveness />}
          {activeStep > 3 && <LimitExceededPassport />}
        </div>

        {/* -------------------------------Developer Button----------------------------- */}
        {/* <div className="flex flex-row space-x-10 mb-3">
          {activeStep > 0 && (
            <div className="mt-4 text-center">
              <Button
                variant="outlined"
                color="primary"
                onClick={handlePrevious}
                className="h-9"
              >
                {t("previousStep")}
              </Button>
            </div>
          )}

          {activeStep < 3 && (
            <div className="mt-4 text-center">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleNext()}
                className="h-9"
              >
                Next Step
              </Button>
            </div>
          )}
        </div> */}
        {/* -------------------------------Developer Button Ends----------------------------- */}
      </div>

      {/* ---------------------------------End-------------------------------------------- */}
    </div>
  );
};

export default HomePage;
